<template>
  <v-dialog :value="value" persistent width="800">
      <v-card color="fill">
        <template v-if="isShuttling">
          <v-container class="pa-6">
            <v-progress-linear indeterminate height="25" rounded>
            Preparing to Shuttle...
            </v-progress-linear>
          </v-container>
          
        </template>
        <template v-else>
        <v-card-title :class="title">Shuttle Ad</v-card-title>
        <v-container class="ma-0 pa-0">
          <v-data-table
            height="500"
            dense
            :items.sync="adGroups"
            :headers="headers"
            :search="search"
            :loading="loading"
            :items-per-page="pageSize"
            hide-default-footer
            fixed-header
            item-key="party_name"
            item-value="party_id"
            show-select
            v-model='selected'
          >

            <template #top>
              <v-toolbar>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="Filter Parties" prepend-inner-icon="mdi-filter-variant" v-model="search" dense outlined class='mt-4'/>
                  </v-col>
                </v-row>                 
              </v-toolbar>
            </template>
          </v-data-table>
        </v-container>

            <v-toolbar color="fill" flat>
              <span v-if='!permit' class='primary--text'>Cannot Shuttle an Ad with no Promo Items</span>
                <v-spacer/>
                <v-btn :class="body" text @click.stop="$emit('update:value', false)">Cancel</v-btn>

                <v-btn 
                  :class="body" 
                  class="ma-2"
                  :loading="isShuttling"
                  :disabled="!permit"
                  color="success"
                  @click.stop="bulkShuttle"
                  >Shuttle
                </v-btn>
            </v-toolbar>
          <!-- 
        <v-toolbar color="fill" flat>
          <span v-if='!permit' class='primary--text'>Cannot Shuttle an Ad with no Promo Items</span>
          <v-spacer/>
          <v-btn :class="body" text @click.stop="$emit('update:value', false)">Cancel</v-btn>
          <v-btn :class="body" :disabled='!permit' class="success white--text ml-2" @click.stop='bulkShuttle'>Shuttle</v-btn>
        </v-toolbar> -->
        </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import Bottleneck from 'bottleneck'

export default {
  name: 'AdShuttle',

  props: ['value', 'selectedAd', 'defaultParty'],

  mixins: [displayHelpers],

  data () {
    return {
      search: '',
      selected:[],
      selections:[],
      adGroups:[],
      ids:[],
      items: [],
      loading: false,
      isShuttling: false,
      upstream: [],
      downstream: [],
      upstreamRes:[],
      downstreamRes:[],
      downstreamParams:{},
      priorShuttles:[],
      permit: false,
      partyInfo: {},
      pageSize: 100
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Ad Groups', sortable: true, filterable: true, value: 'party_name' },
      ]
    } 
  },

  async created () {
  },
  
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value){
        this.getAdGroupsForShuttle()
        this.countItems()
        } 
      }
    },
  },

  methods: {
    async getAdGroupsForShuttle() {
      this.loading = true
      await this.$Ads.getShuttleAdGroups(this.selectedAd.id, this.defaultParty.id).then(res => {
        this.adGroups = res.data

        this.loading = false
      }).catch(err=>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Ad Group List due to ${err?.response?.data?.message || err.message}` })
        })
    },

    async shuttleAd (id){
      const body = {party_id:id, note:''}
      const ad_id = this.selectedAd.id
      if(this.permit === true){
      await this.$Ads.shuttle(ad_id, body)}
      else{
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Cannot Shuttle An Ad With No Promo Items` })
      }
    },

    async bulkShuttle() {
      this.isShuttling = true
      try {
          const limiter = new Bottleneck({
          reservoir: 25,
          reservoirRefreshAmount: 25,
          reservoirRefreshInterval: 5 * 1000,
          maxConcurrent: 25,
          minTime: 0
          })
          const throttledShuttle = limiter.wrap(this.shuttleAd)

          const allThePromises = this.selected.map(party => {
          return throttledShuttle(party.party_id)
        })

        await Promise.all(allThePromises)
          this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Shuttle successfully submitted` })
        } catch(err) {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Shuttle failed due to ${err?.response?.data?.message || err.message}` })
        } finally {
          this.isShuttling = false
        }
      },

    async checkShuttleStatus(){
      this.loading= true
      await this.$Ads.shuttleHistory(this.selectedAd.id).then(res=>{
        this.priorShuttles = res.data
        this.downstreamRes.forEach(element=>{
          this.priorShuttles.forEach(element2=>{
            if (element.id == element2.party_id){
              this.downstreamRes = this._.pull(this.downstreamRes, element)
            }
          })
       })
      }).catch(err=>{
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to check shuttle status due to ${err?.response?.data?.message || err.message}` })
      }).finally(this.loading = false)
      this.selections= this.downstreamRes
    },

    async countItems(){
      await this.$Ads.countItems(this.selectedAd.id).then(res=>{
        if(res.data.number_of_items != 0){
          this.permit = true
        }
        else this.permit = false
      }).catch(err=>{
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to check shuttle status due to ${err?.response?.data?.message || err.message}` })
      })
    }

  }

}
</script>
